<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Editar indirectos</h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        @click="addCost()"
        v-if="!article && !priceList"
        >Agregar indirectos</b-button
      >
      <b-button
        type="is-primary"
        @click="addProviderCost()"
        v-if="article && !priceList"
        >Agregar indirectos</b-button
      >
      <b-button type="is-primary" @click="addPricesListCost()" v-if="priceList"
        >Agregar indirectos</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Los indirectos sin
      nombre no serán <span class="global-form-hint-color"> guardados. </span>
    </div>

    <div v-if="isPricesList">
      <div
        v-for="(singleDiscount, index) in itemValue.COSTOS"
        :key="index"
        class="margin-bottom-add"
      >
        <h3 class="form-division">Descuento {{ singleDiscount.NOMBRE }}</h3>

        <b-field grouped class="special-margin">
          <b-field
            label="Nombre del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descuento 1"
              expanded
              icon="file-outline"
              v-model="singleDiscount.NOMBRE"
              required
            ></b-input>
          </b-field>
          <b-field
            label="Descripción del descuento (Opcional)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descripción 1"
              expanded
              icon="newspaper-variant-outline"
              v-model="singleDiscount.DESCRIPCION"
            ></b-input>
          </b-field>
        </b-field>

        <b-field grouped class="special-margin">
          <b-field
            label="Porcentaje del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="percent-outline"
              v-model="singleDiscount.PORCENTAJE"
              required
              @change.native="setPriceListQuantity(singleDiscount)"
              type="number"
              step="any"
            />
          </b-field>
          <b-field
            label="Cantidad del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="currency-usd"
              v-model="singleDiscount.CANTIDAD"
              required
              @change.native="setPriceListPercentage(singleDiscount)"
              type="number"
              step="any"
            ></b-input>
          </b-field>
        </b-field>

        <b-button
          type="is-danger"
          @click="removePricesListCost(index)"
          class="special-margin"
          >Eliminar descuento {{ singleDiscount.NOMBRE }}</b-button
        >
      </div>
    </div>

    <div v-if="isGeneral">
      <div
        v-for="(singleCost, index) in itemValue.costs"
        :key="index"
        margin-bottom-add
      >
        <h3 class="form-division">Indirecto {{ singleCost.NOMBRE }}</h3>

        <b-field grouped class="special-margin">
          <b-field
            label="Nombre del indirecto (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Indirecto 1"
              expanded
              icon="file-outline"
              v-model="singleCost.NOMBRE"
              required
            ></b-input>
          </b-field>
          <b-field
            label="Descripción del indirecto (Opcional)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descripcion 1"
              expanded
              icon="newspaper-variant-outline"
              v-model="singleCost.DESCRIPCION"
            ></b-input>
          </b-field>
        </b-field>

        <b-field grouped class="special-margin">
          <b-field
            label="Porcentaje del indirecto (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="percent-outline"
              v-model="singleCost.PORCENTAJE"
              required
              @change.native="setCostQuantity()"
              type="number"
              step="any"
            />
          </b-field>
          <b-field
            label="Cantidad del indirecto (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="currency-usd"
              v-model="singleCost.CANTIDAD"
              required
              @change.native="setPercentage()"
              type="number"
              step="any"
            ></b-input>
          </b-field>
        </b-field>

        <b-button
          type="is-danger"
          @click="removeCost(index)"
          class="special-margin"
          >Eliminar indirecto {{ singleCost.NOMBRE }}</b-button
        >
      </div>
    </div>

    <div v-if="isProvider">
      <div
        v-for="(singleCost, index) in itemValue.COSTOS"
        :key="index"
        class="margin-bottom-add"
      >
        <h3 class="form-division">Indirecto {{ singleCost.NOMBRE }}</h3>

        <b-field grouped class="special-margin">
          <b-field
            label="Nombre del indirecto (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Indirecto 1"
              expanded
              icon="file-outline"
              v-model="singleCost.NOMBRE"
              required
            ></b-input>
          </b-field>
          <b-field
            label="Descripción del indirecto (Opcional)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descripcion 1"
              expanded
              icon="newspaper-variant-outline"
              v-model="singleCost.DESCRIPCION"
            ></b-input>
          </b-field>
        </b-field>

        <b-field grouped class="special-margin">
          <b-field
            label="Porcentaje del indirecto (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="percent-outline"
              v-model="singleCost.PORCENTAJE"
              required
              @change.native="setCostProviderQuantity()"
              type="number"
              step="any"
            />
          </b-field>
          <b-field
            label="Cantidad del indirecto (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="currency-usd"
              v-model="singleCost.CANTIDAD"
              required
              @change.native="setCostProviderPercentage()"
              type="number"
              step="any"
            ></b-input>
          </b-field>
        </b-field>

        <b-button
          type="is-danger"
          @click="removeProviderCost(index)"
          class="special-margin"
          >Eliminar indirecto {{ singleCost.NOMBRE }}</b-button
        >
      </div>
    </div>

    <div class="add-global-controls providers-list-global">
      <b-button
        type="is-success"
        @click="sendCostPriceList()"
        v-if="isPricesList"
        >Guardar cambios</b-button
      >
      <b-button type="is-success" @click="sendCost()" v-if="isGeneral"
        >Guardar cambios</b-button
      >
      <b-button type="is-success" @click="sendCostProvider()" v-if="isProvider"
        >Guardar cambios</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../../event-bus";
import { round } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "AddCost",
  props: [
    "item",
    "article",
    "presentation",
    "isPurchaseOrder",
    "currentIndex",
    "extraInfo",
    "priceList",
    "isProvider",
    "isPricesList",
    "isGeneral",
  ],
  components: {},
  data() {
    return {
      itemValue: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addCost() {
      this.itemValue.costs.push({
        NOMBRE: "",
        DESCRIPCION: "",
        PORCENTAJE: 0,
        CANTIDAD: 0,
      });
    },
    addProviderCost() {
      this.itemValue.COSTOS.push({
        NOMBRE: "",
        DESCRIPCION: "",
        PORCENTAJE: 0,
        CANTIDAD: 0,
      });
    },
    addPricesListCost() {
      this.itemValue.COSTOS.push({
        NOMBRE: "",
        DESCRIPCION: "",
        PORCENTAJE: 0,
        CANTIDAD: 0,
      });
    },
    checkNotValid() {
      for (const singleCost of this.itemValue.costs) {
        if (
          !singleCost.NOMBRE ||
          !singleCost.PORCENTAJE ||
          !singleCost.CANTIDAD
        ) {
          return false;
        }
      }
      return true;
    },
    checkNotValidProviders() {
      for (const singleCost of this.itemValue.COSTOS) {
        if (
          !singleCost.NOMBRE ||
          !singleCost.PORCENTAJE ||
          !singleCost.CANTIDAD
        ) {
          return false;
        }
      }
      return true;
    },
    checkNotValidPricesList() {
      for (const singleCost of this.itemValue.COSTOS) {
        if (
          !singleCost.NOMBRE ||
          !singleCost.PORCENTAJE ||
          !singleCost.CANTIDAD
        ) {
          return false;
        }
      }
      return true;
    },
    removeCost(index) {
      this.itemValue.costs.splice(index, 1);
      this.setCostQuantity();
      this.setPercentage();
    },
    removeProviderCost(index) {
      this.itemValue.COSTOS.splice(index, 1);
      this.setCostProviderQuantity();
      this.setCostProviderPercentage();
    },
    removePricesListCost(index) {
      this.itemValue.COSTOS.splice(index, 1);
      this.setPriceListQuantity();
      this.setPriceListPercentage();
    },
    async sendCost() {
      try {
        if (!this.checkNotValid()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los indirecto",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        EventBus.$emit(
          this.isPurchaseOrder ? "addPurchaseOrderCost" : "addSaleCost",
          {
            article: this.itemValue.article,
            costs: this.itemValue.costs,
            purchaseOrderIndex: this.purchaseOrderIndex,
            currentIndex: this.currentIndex,
            extraInfo: this.extraInfo,
          }
        );
        this.$buefy.dialog.confirm({
          title: "Éxito",
          message: "Se agregó exitosamente el indirecto",
          confirmText: "Entendido",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.closeModal();
          },
          cancelText: "cancelar",
          canCancel: false,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar el indirecto",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async sendCostProvider() {
      try {
        if (!this.checkNotValidProviders()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los indirecto",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let response = await this.$store.dispatch("SAVEPROVIDERCOSTS", {
          article: this.article,
          costs: this.itemValue,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se agregaron exitosamente los indirectos",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al agregar los indirectos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los indirectos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async sendCostPriceList() {
      try {
        if (!this.checkNotValidPricesList()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los indirecto",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        let response = await this.$store.dispatch("SAVEPRICESLISTCOSTS", {
          priceListId: this.item.CLAVE_LISTAP,
          articleId: this.article,
          presentationId: this.presentation,
          priceListInformation: this.itemValue,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se agregaron exitosamente los indirectos",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al agregar los indirectos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los indirectos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    setCostQuantity() {
      let initialCost = Number(this.itemValue.price);

      // Calculate discounts using waterfall method
      for (const singleDiscount of this.itemValue.discounts) {
        initialCost -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using waterfall method
      for (const [
        singleCostIndex,
        singleCost,
      ] of this.itemValue.costs.entries()) {
        if (singleCost.PORCENTAJE) {
          let currentDiscountQuantity = round(
            parseFloat(initialCost * (singleCost.PORCENTAJE / 100)).toFixed(4),
            4
          );
          initialCost += Number(currentDiscountQuantity);
          this.itemValue.costs[singleCostIndex].CANTIDAD =
            currentDiscountQuantity;
        }
      }
    },
    setCostProviderQuantity() {
      let initialCost = Number(this.itemValue.COSTO);

      // Calculate discounts using waterfall method
      for (const singleDiscount of this.itemValue.DESCUENTOS) {
        initialCost -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using waterfall method
      for (const [
        singleCostIndex,
        singleCost,
      ] of this.itemValue.COSTOS.entries()) {
        if (singleCost.PORCENTAJE) {
          let currentDiscountQuantity = round(
            parseFloat(initialCost * (singleCost.PORCENTAJE / 100)).toFixed(4),
            4
          );
          initialCost += Number(currentDiscountQuantity);
          this.itemValue.COSTOS[singleCostIndex].CANTIDAD =
            currentDiscountQuantity;
        }
      }
    },
    setPriceListQuantity() {
      let initialCost = Number(this.itemValue.calculatedPrice);

      // Calculate discounts using waterfall method
      for (const singleDiscount of this.itemValue.DESCUENTOS) {
        initialCost -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using waterfall method
      for (const [
        singleCostIndex,
        singleCost,
      ] of this.itemValue.COSTOS.entries()) {
        if (singleCost.PORCENTAJE) {
          let currentDiscountQuantity = round(
            parseFloat(initialCost * (singleCost.PORCENTAJE / 100)).toFixed(4),
            4
          );
          initialCost += Number(currentDiscountQuantity);
          this.itemValue.COSTOS[singleCostIndex].CANTIDAD =
            currentDiscountQuantity;
        }
      }
    },
    setPercentage() {
      let initialCost = Number(this.itemValue.price);

      // Calculate discounts using waterfall method
      for (const singleDiscount of this.itemValue.discounts) {
        initialCost -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using waterfall method
      for (const [
        singleCostIndex,
        singleCost,
      ] of this.itemValue.costs.entries()) {
        if (singleCost.CANTIDAD) {
          let currentCostPecentage = round(
            parseFloat((singleCost.CANTIDAD * 100) / initialCost).toFixed(4),
            4
          );
          initialCost += Number(singleCost.CANTIDAD);
          this.itemValue.costs[singleCostIndex].PORCENTAJE =
            currentCostPecentage;
        }
      }
    },
    setCostProviderPercentage() {
      let initialCost = Number(this.itemValue.COSTO);

      // Calculate discounts using waterfall method
      for (const singleDiscount of this.itemValue.DESCS) {
        initialCost -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using waterfall method
      for (const [
        singleCostIndex,
        singleCost,
      ] of this.itemValue.COSTOS.entries()) {
        if (singleCost.CANTIDAD) {
          let currentCostPecentage = round(
            parseFloat((singleCost.CANTIDAD * 100) / initialCost).toFixed(4),
            4
          );
          initialCost += Number(singleCost.CANTIDAD);
          this.itemValue.COSTOS[singleCostIndex].PORCENTAJE =
            currentCostPecentage;
        }
      }
    },
    setPriceListPercentage() {
      let initialCost = Number(this.itemValue.calculatedPrice);

      // Calculate discounts using waterfall method
      for (const singleDiscount of this.itemValue.DESCUENTOS) {
        initialCost -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using waterfall method
      for (const [
        singleCostIndex,
        singleCost,
      ] of this.itemValue.COSTOS.entries()) {
        if (singleCost.CANTIDAD) {
          let currentCostPecentage = round(
            parseFloat((singleCost.CANTIDAD * 100) / initialCost).toFixed(4),
            4
          );
          initialCost += Number(singleCost.CANTIDAD);
          this.itemValue.COSTOS[singleCostIndex].PORCENTAJE =
            currentCostPecentage;
        }
      }
    },
  },
  mounted() {
    if (this.item) {
      this.itemValue = {
        ...this.item,
        discounts: this.item.discounts ? [...this.item.discounts] : [],
        costs: this.item.costs ? [...this.item.costs] : [],
        COSTOS: this.item.COSTOS ? [...this.item.COSTOS] : [],
        DESCS: this.item.DESCS ? [...this.item.DESCS] : [],
        DESCUENTOS: this.item.DESCUENTOS ? [...this.item.DESCUENTOS] : [],
      };
    }
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
