<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Editar descuentos</h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        @click="addDiscount()"
        v-if="!article && !priceList"
        >Agregar descuento</b-button
      >
      <b-button
        type="is-primary"
        @click="addProviderDiscount()"
        v-if="article && !priceList"
        >Agregar descuento</b-button
      >
      <b-button
        type="is-primary"
        @click="addPricesListDiscount()"
        v-if="priceList"
        >Agregar descuento</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Los descuentos sin
      nombre no serán <span class="global-form-hint-color"> guardados. </span>
    </div>

    <div v-if="isPricesList">
      <div
        v-for="(singleDiscount, index) in itemValue.DESCUENTOS"
        :key="index"
        class="margin-bottom-add"
      >
        <h3 class="form-division">Descuento {{ singleDiscount.NOMBRE }}</h3>

        <b-field grouped class="special-margin">
          <b-field
            label="Nombre del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descuento 1"
              expanded
              icon="file-outline"
              v-model="singleDiscount.NOMBRE"
              required
            ></b-input>
          </b-field>
          <b-field
            label="Descripción del descuento (Opcional)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descripción 1"
              expanded
              icon="newspaper-variant-outline"
              v-model="singleDiscount.DESCRIPCION"
            ></b-input>
          </b-field>
        </b-field>

        <b-field grouped class="special-margin">
          <b-field
            label="Porcentaje del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="percent-outline"
              v-model="singleDiscount.PORCENTAJE"
              required
              @change.native="setPriceListQuantity(singleDiscount)"
              type="number"
              step="any"
            />
          </b-field>
          <b-field
            label="Cantidad del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="currency-usd"
              v-model="singleDiscount.CANTIDAD"
              required
              @change.native="setPriceListPercentage(singleDiscount)"
              type="number"
              step="any"
            ></b-input>
          </b-field>
        </b-field>

        <b-button
          type="is-danger"
          @click="removePricesListDiscount(index)"
          class="special-margin"
          >Eliminar descuento {{ singleDiscount.NOMBRE }}</b-button
        >
      </div>
    </div>

    <div v-if="isGeneral">
      <div
        v-for="(singleDiscount, index) in itemValue.discounts"
        :key="index"
        class="margin-bottom-add"
      >
        <h3 class="form-division">Descuento {{ singleDiscount.NOMBRE }}</h3>

        <b-field grouped class="special-margin">
          <b-field
            label="Nombre del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descuento 1"
              expanded
              icon="file-outline"
              v-model="singleDiscount.NOMBRE"
              required
            ></b-input>
          </b-field>
          <b-field
            label="Descripción del descuento (Opcional)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descripción 1"
              expanded
              icon="newspaper-variant-outline"
              v-model="singleDiscount.DESCRIPCION"
            ></b-input>
          </b-field>
        </b-field>

        <b-field grouped class="special-margin">
          <b-field
            label="Porcentaje del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="percent-outline"
              v-model="singleDiscount.PORCENTAJE"
              required
              @change.native="setDiscountQuantity()"
              type="number"
              step="any"
            />
          </b-field>
          <b-field
            label="Cantidad del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="currency-usd"
              v-model="singleDiscount.CANTIDAD"
              required
              @change.native="setPercentage()"
              type="number"
              step="any"
            ></b-input>
          </b-field>
        </b-field>

        <b-button
          type="is-danger"
          @click="removeDiscount(index)"
          class="special-margin"
          >Eliminar descuento {{ singleDiscount.NOMBRE }}</b-button
        >
      </div>
    </div>

    <div v-if="isProvider">
      <div
        v-for="(singleDiscount, index) in itemValue.DESCS"
        :key="index"
        class="margin-bottom-add"
      >
        <h3 class="form-division">Descuento {{ singleDiscount.NOMBRE }}</h3>

        <b-field grouped class="special-margin">
          <b-field
            label="Nombre del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descuento 1"
              expanded
              icon="file-outline"
              v-model="singleDiscount.NOMBRE"
              required
            ></b-input>
          </b-field>
          <b-field
            label="Descripción del descuento (Opcional)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: Descripción 1"
              expanded
              icon="newspaper-variant-outline"
              v-model="singleDiscount.DESCRIPCION"
            ></b-input>
          </b-field>
        </b-field>

        <b-field grouped class="special-margin">
          <b-field
            label="Porcentaje del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="percent-outline"
              v-model="singleDiscount.PORCENTAJE"
              required
              @change.native="setProviderDiscountQuantity()"
              type="number"
              step="any"
            />
          </b-field>
          <b-field
            label="Cantidad del descuento (Requerido)"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 500"
              expanded
              icon="currency-usd"
              v-model="singleDiscount.CANTIDAD"
              required
              @change.native="setProviderPercentage()"
              type="number"
              step="any"
            ></b-input>
          </b-field>
        </b-field>

        <b-button
          type="is-danger"
          @click="removeProviderDiscount(index)"
          class="special-margin"
          >Eliminar descuento {{ singleDiscount.NOMBRE }}</b-button
        >
      </div>
    </div>

    <div class="add-global-controls providers-list-global">
      <b-button
        type="is-success"
        @click="sendDiscountPriceList()"
        v-if="isPricesList"
        >Guardar cambios</b-button
      >
      <b-button type="is-success" @click="sendDiscount()" v-if="isGeneral"
        >Guardar cambios</b-button
      >
      <b-button
        type="is-success"
        @click="sendDiscountProvider()"
        v-if="isProvider"
        >Guardar cambios</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../../event-bus";
import { round } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "AddDiscount",
  props: [
    "item",
    "article",
    "isPurchaseOrder",
    "currentIndex",
    "extraInfo",
    "priceList",
    "isProvider",
    "isPricesList",
    "isGeneral",
    "isSale",
  ],
  components: {},
  data() {
    return {
      itemValue: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async sendDiscount() {
      try {
        if (!this.checkNotValid()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los descuentos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        EventBus.$emit(
          this.isPurchaseOrder ? "addPurchaseOrderDiscount" : "addSaleDiscount",
          {
            article: this.itemValue.article,
            discounts: this.itemValue.discounts,
            currentIndex: this.currentIndex,
            extraInfo: this.extraInfo,
          }
        );
        this.$buefy.dialog.confirm({
          title: "Éxito",
          message: "Se agregaron exitosamente los descuentos",
          confirmText: "Entendido",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.closeModal();
          },
          cancelText: "cancelar",
          canCancel: false,
        });
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los descuentos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async sendDiscountProvider() {
      try {
        if (!this.checkNotValidProvider()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los descuentos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let response = await this.$store.dispatch("SAVEPROVIDERDISCOUNTS", {
          article: this.article,
          discounts: this.itemValue,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se agregaron exitosamente los descuentos",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al agregar los descuentos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los descuentos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async sendDiscountPriceList() {
      try {
        if (!this.checkNotValidPricesList()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los descuentos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let response = await this.$store.dispatch("SAVEPRICESLISTDISCOUNTS", {
          priceListId: this.item.CLAVE_LISTAP,
          articleId: this.article,
          presentationId: this.presentation,
          priceListInformation: this.itemValue,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se agregaron exitosamente los descuentos",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al agregar los desuentos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los descuentos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkNotValid() {
      for (const singleDiscount of this.itemValue.discounts) {
        if (
          !singleDiscount.NOMBRE ||
          !singleDiscount.PORCENTAJE ||
          !singleDiscount.CANTIDAD
        ) {
          return false;
        }
      }
      return true;
    },
    checkNotValidProvider() {
      for (const singleDiscount of this.itemValue.DESCS) {
        if (
          !singleDiscount.NOMBRE ||
          !singleDiscount.PORCENTAJE ||
          !singleDiscount.CANTIDAD
        ) {
          return false;
        }
      }
      return true;
    },
    checkNotValidPricesList() {
      for (const singleDiscount of this.itemValue.DESCUENTOS) {
        if (
          !singleDiscount.NOMBRE ||
          !singleDiscount.PORCENTAJE ||
          !singleDiscount.CANTIDAD
        ) {
          return false;
        }
      }
      return true;
    },
    removeDiscount(index) {
      this.itemValue.discounts.splice(index, 1);
      this.setDiscountQuantity();
      this.setPercentage();
    },
    removeProviderDiscount(index) {
      this.itemValue.DESCS.splice(index, 1);
      this.setProviderDiscountQuantity();
      this.setProviderPercentage();
    },
    removePricesListDiscount(index) {
      this.itemValue.DESCUENTOS.splice(index, 1);
      this.setPriceListQuantity();
      this.setPriceListPercentage();
    },
    setDiscountQuantity() {
      let initialCost = Number(this.itemValue.price);
      for (const [
        singleDiscountIndex,
        singleDiscount,
      ] of this.itemValue.discounts.entries()) {
        if (singleDiscount.PORCENTAJE) {
          let currentDiscountQuantity = round(
            parseFloat(initialCost * (singleDiscount.PORCENTAJE / 100)).toFixed(
              4
            ),
            4
          );
          initialCost -= Number(currentDiscountQuantity);
          this.itemValue.discounts[singleDiscountIndex].CANTIDAD =
            currentDiscountQuantity;
        }
      }
    },
    setProviderDiscountQuantity() {
      let initialCost = Number(this.itemValue.COSTO);
      for (const [
        singleDiscountIndex,
        singleDiscount,
      ] of this.itemValue.DESCS.entries()) {
        if (singleDiscount.PORCENTAJE) {
          let currentDiscountQuantity = round(
            parseFloat(initialCost * (singleDiscount.PORCENTAJE / 100)).toFixed(
              4
            ),
            4
          );
          initialCost -= Number(currentDiscountQuantity);
          this.itemValue.DESCS[singleDiscountIndex].CANTIDAD =
            currentDiscountQuantity;
        }
      }
    },
    setPriceListQuantity() {
      let initialCost = Number(this.itemValue.calculatedPrice);
      for (const [
        singleDiscountIndex,
        singleDiscount,
      ] of this.itemValue.DESCUENTOS.entries()) {
        if (singleDiscount.PORCENTAJE) {
          let currentDiscountQuantity = round(
            parseFloat(initialCost * (singleDiscount.PORCENTAJE / 100)).toFixed(
              4
            ),
            4
          );
          initialCost -= Number(currentDiscountQuantity);
          this.itemValue.DESCUENTOS[singleDiscountIndex].CANTIDAD =
            currentDiscountQuantity;
        }
      }
    },
    setPercentage() {
      let initialCost = Number(this.itemValue.price);
      for (const [
        singleDiscountIndex,
        singleDiscount,
      ] of this.itemValue.discounts.entries()) {
        if (singleDiscount.CANTIDAD) {
          let currentDiscountPecentage = round(
            parseFloat((singleDiscount.CANTIDAD * 100) / initialCost).toFixed(
              4
            ),
            4
          );
          initialCost -= Number(singleDiscount.CANTIDAD);
          this.itemValue.discounts[singleDiscountIndex].PORCENTAJE =
            currentDiscountPecentage;
        }
      }
    },
    setProviderPercentage() {
      let initialCost = Number(this.itemValue.COSTO);
      for (const [
        singleDiscountIndex,
        singleDiscount,
      ] of this.itemValue.DESCS.entries()) {
        if (singleDiscount.CANTIDAD) {
          let currentDiscountPecentage = round(
            parseFloat((singleDiscount.CANTIDAD * 100) / initialCost).toFixed(
              4
            ),
            4
          );
          initialCost -= Number(singleDiscount.CANTIDAD);
          this.itemValue.DESCS[singleDiscountIndex].PORCENTAJE =
            currentDiscountPecentage;
        }
      }
    },
    setPriceListPercentage() {
      let initialCost = Number(this.itemValue.calculatedPrice);
      for (const [
        singleDiscountIndex,
        singleDiscount,
      ] of this.itemValue.DESCUENTOS.entries()) {
        if (singleDiscount.CANTIDAD) {
          let currentDiscountPecentage = round(
            parseFloat((singleDiscount.CANTIDAD * 100) / initialCost).toFixed(
              4
            ),
            4
          );
          initialCost -= Number(singleDiscount.CANTIDAD);
          this.itemValue.DESCUENTOS[singleDiscountIndex].PORCENTAJE =
            currentDiscountPecentage;
        }
      }
    },
    addDiscount() {
      this.itemValue.discounts.push({
        NOMBRE: "",
        DESCRIPCION: "",
        PORCENTAJE: 0,
        CANTIDAD: 0,
      });
    },
    addProviderDiscount() {
      this.itemValue.DESCS.push({
        NOMBRE: "",
        DESCRIPCION: "",
        PORCENTAJE: 0,
        CANTIDAD: 0,
      });
    },
    addPricesListDiscount() {
      this.itemValue.DESCUENTOS.push({
        NOMBRE: "",
        DESCRIPCION: "",
        PORCENTAJE: 0,
        CANTIDAD: 0,
      });
    },
  },
  mounted() {
    if (this.item) {
      this.itemValue = {
        ...this.item,
        discounts: this.item.discounts ? [...this.item.discounts] : [],
        costs: this.item.costs ? [...this.item.costs] : [],
        COSTOS: this.item.COSTOS ? [...this.item.COSTOS] : [],
        DESCS: this.item.DESCS ? [...this.item.DESCS] : [],
        DESCUENTOS: this.item.DESCUENTOS ? [...this.item.DESCUENTOS] : [],
      };
    }
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
@import "../../../Purchases/style/AddPurchaseOrder.css";
</style>
